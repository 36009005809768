declare var document: any;

export enum EPlaycanvasEvents {
  IFrameReady = "IFrameReady",
  ParseData = "ParseData",
  StartGame = "StartGame",
  SceneLoaded = "SceneLoaded"
}

class Playcanvas {
  private iframeContent: any;
  public iframeReady: boolean;
  private messagesQueue: any[];
  private isReady: Function;
  private onTutorial: Function;

  constructor(
    setconfirm: Function,
    setLoaded: Function
  ) {
    this.iframeReady = false;
    this.messagesQueue = [];
    this.isReady = setconfirm;
    this.onTutorial = setLoaded; 
  }

  connectToIframe() {
    // @ts-ignore
    this.iframeContent = document.getElementById("verse-canvas").contentWindow;

    // --- postMessage event handlers
    window.addEventListener("message", this.parseMessage.bind(this));
  }

  parseMessage(event: any) {
    if (!event.data || !event.data.type) return;
    
    switch (event.data.type) {
      case EPlaycanvasEvents.IFrameReady:
        this.iframeReady = true;
        this.isReady(true)
        // --- send queue messages
        this.messagesQueue.forEach(message => {
          this.sendMessage(message.type, message.data);
        });
        break;
      case EPlaycanvasEvents.SceneLoaded:
        this.onTutorial(true)
        break;
      case EPlaycanvasEvents.StartGame:
        break;
    }
  }

  sendMessage(type: EPlaycanvasEvents, data?: any) {
    // --- if we aren't ready, queue messages for sending later
    if (this.iframeReady === false) {
      this.messagesQueue.push({
        type: type,
        data: data
      });
      return;
    }

    this.iframeContent.postMessage(
      {
        type: type,
        data: data
      },
      "*"
    );
  }
}

export default Playcanvas;