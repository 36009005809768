import React from "react";
import style from '../style/tutorial.module.css';
import {ReactComponent as IconTouchPan} from '../assets/icons/icon-touch-pan.svg';
import {ReactComponent as IconTouchZoom} from '../assets/icons/icon-touch-zoom.svg';
import {ReactComponent as IconKeyWasd} from '../assets/icons/icon-key-wasd.svg';
import {ReactComponent as IconKeyUpdown} from '../assets/icons/icon-key-updown.svg';
import {ReactComponent as IconMousePan} from '../assets/icons/icon-mouse-pan.svg';

import {isMobile} from 'react-device-detect';

type Props = {
  trigger: Function
}

const IsMobile:React.FC = () => {
  return (
    <>
    <div className={style.isPc}>
      <div className={style.isPcRow}>
        <div className={style.iconWrap}>
          <IconTouchPan className={`${style.iconPcSmall} ${style.icon}`} /> 
        </div>
        <div className={style.textWrap}>
          <p>
            <big>視点移動</big>
            タッチ＆ドラッグ
          </p>
        </div>
      </div>
      <div className={style.isPcRow}>
        <div className={style.iconWrap}>
          <IconTouchZoom className={`${style.iconPcSmall} ${style.icon}`} /> 
        </div>
        <div className={style.textWrap}>
          <p>
            <big>移動</big>
            拡大：前移動<br />
            縮小：後移動</p>
        </div>
      </div>
    </div>
    </>
  )
}

const IsPc:React.FC  = () => {
  return (
    <>
    <div className={style.isPc}>
      <div className={style.isPcRow}>
        <div className={style.iconWrap}>
          <IconKeyWasd className={`${style.iconPcSmall} ${style.icon}`} /> 
          or
          <IconKeyUpdown className={`${style.iconPcSmall} ${style.icon}`}/> 
        </div>
        <div className={style.textWrap}>
          <p>移動(前後左右)</p>
        </div>
      </div>
      <div className={style.isPcRow}>
        <div className={style.iconWrap}>
          <IconMousePan className={`${style.iconPc} ${style.icon}`} /> 
        </div>
        <div className={style.textWrap}>
          <p>
            <big>視点移動</big>
            左クリック<br />＆マウス移動</p>
        </div>
      </div>
    </div>
    </>
  )
}
export const Tutorial:React.FC<Props> = ({trigger}) => {
  return (
    <div className={style.wrapper}>
        <div className={style.cover}></div>
        <div className={style.inner}>
          <div className={style.title}>
            How To Control
          </div>
          <div className={style.contents}>
            { isMobile ? <IsMobile /> : <IsPc /> }
            <button className={style.button} onClick={(e) => trigger(false)}>
            START
          </button>
          </div>
          
        </div> 
    </div>
  )
}

export default Tutorial