import React, { useEffect, useRef, useState } from 'react';
import style from './style/index.module.css';
import './style/global.css';
import Tutorial from './components/tutorial';
import Playcanvas from './lib/playcanvas';

const IframeUrl = `https://playcanv.as/e/p/${process.env.REACT_APP_IFRAME_ID}/?overlay=false`

function App() {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false)
  const control = useRef<Playcanvas>(new Playcanvas(setConfirm, setLoaded))

  useEffect(() => {
    if(control.current) {
      control.current.connectToIframe();
    }
  }, [])

  useEffect(() => {
    if(loaded) {
      setConfirm(false)
    }
  }, [loaded])
  
  
  return (
    <div className={style.app}>
      { confirm ? <>
      <div className={style.spinner}>
      Wait a second...
      </div>
      </>: null }
      { loaded ? <>
        <Tutorial trigger={setLoaded} />
      </> : null }
       
       <iframe id="verse-canvas" loading="lazy" title="embedCanvas" src={IframeUrl}></iframe>
    </div>
  );
}

export default App;
